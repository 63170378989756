var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c("div", { staticClass: "vx-row no-gutter" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center",
                    },
                    [
                      _c("img", {
                        staticClass: "mx-auto",
                        attrs: {
                          src: require("@/assets/images/pages/register.jpg"),
                          alt: "register",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg",
                    },
                    [
                      _c("div", { staticClass: "p-8" }, [
                        _c("div", { staticClass: "vx-card__title" }, [
                          _c("h4", { staticClass: "mb-4" }, [
                            _vm._v("Create Account"),
                          ]),
                          _c("p", [
                            _vm._v(
                              "Fill the below form to create a new account."
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "clearfix" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|alpha_dash|min:3",
                                  expression: "'required|alpha_dash|min:3'",
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                "label-placeholder": "Username",
                                name: "username",
                                placeholder: "Username",
                              },
                              model: {
                                value: _vm.username,
                                callback: function ($$v) {
                                  _vm.username = $$v
                                },
                                expression: "username",
                              },
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(_vm._s(_vm.errors.first("username"))),
                            ]),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|email",
                                  expression: "'required|email'",
                                },
                              ],
                              staticClass: "w-full mt-6",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                name: "email",
                                type: "email",
                                "label-placeholder": "Email",
                                placeholder: "Email",
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(_vm._s(_vm.errors.first("email"))),
                            ]),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|min:6|max:10",
                                  expression: "'required|min:6|max:10'",
                                },
                              ],
                              ref: "password",
                              staticClass: "w-full mt-6",
                              attrs: {
                                type: "password",
                                "data-vv-validate-on": "blur",
                                name: "password",
                                "label-placeholder": "Password",
                                placeholder: "Password",
                              },
                              model: {
                                value: _vm.password,
                                callback: function ($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password",
                              },
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(_vm._s(_vm.errors.first("password"))),
                            ]),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "min:6|max:10|confirmed:password",
                                  expression:
                                    "'min:6|max:10|confirmed:password'",
                                },
                              ],
                              staticClass: "w-full mt-6",
                              attrs: {
                                type: "password",
                                "data-vv-validate-on": "blur",
                                "data-vv-as": "password",
                                name: "confirm_password",
                                "label-placeholder": "Confirm Password",
                                placeholder: "Confirm Password",
                              },
                              model: {
                                value: _vm.confirm_password,
                                callback: function ($$v) {
                                  _vm.confirm_password = $$v
                                },
                                expression: "confirm_password",
                              },
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(
                                _vm._s(_vm.errors.first("confirm_password"))
                              ),
                            ]),
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mt-6",
                                model: {
                                  value: _vm.isTermsConditionAccepted,
                                  callback: function ($$v) {
                                    _vm.isTermsConditionAccepted = $$v
                                  },
                                  expression: "isTermsConditionAccepted",
                                },
                              },
                              [_vm._v("I accept the terms & conditions.")]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass: "mt-6",
                                attrs: { type: "border", to: "/pages/login" },
                              },
                              [_vm._v("Login")]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass: "float-right mt-6",
                                attrs: { disabled: !_vm.validateForm },
                                on: { click: _vm.registerUser },
                              },
                              [_vm._v("Register")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }